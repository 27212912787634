.chartist-tooltip {
  position: absolute;

  background-color: rgba(0, 0, 0, 0.54);
  color: white;
  padding: 0.8rem;
  border-radius: 1rem;

  opacity: 0;
  transition: opacity 0.25s;

  top: 0;
  left: 0;
}

.tooltip-show {
  opacity: 1;
  z-index: 1000;
}

.chartist-tooltip::after {
  content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.54) transparent transparent transparent;
}

#exceptionsList .MuiDataGrid-window {
  overflow-y: hidden !important;
}

#exceptionsList .MuiDataGrid-window::-webkit-scrollbar {
  background: #e0e0e0;
  border-radius: 0.2rem;
  height: 0.8rem;
}

#exceptionsList .MuiDataGrid-window::-webkit-scrollbar-thumb {
  background-color: #0f71d0;
  border-radius: 0.4rem;
}
